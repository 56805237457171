import api from '../../utils/api'
// 血糖检测  血清胃蛋白酶原  癌症胚胎抗原（CEA）  异常项目
// 授权
export async function get_lab (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         patient_name: 'test',
  //         patient_age: 19,
  //         patient_gender: '男',
  //         analysisTime: '',
  //         id:'1',
  //         create_mini_user_id:'2',
  //         groups: [
  //           {
  //             name: ' 血清总睾酮 ',
  //             abnormal: true,
  //             laboratories: [
  //               {
  //                 laboratory_id: 1,
  //                 laboratory_name: '血清总胆固醇[TCHOL]..',
  //                 standard_units: 'mmol/L',
  //                 standard_result: '5.95',
  //                 standard_result_type: 1,
  //                 standard_specimen: '血',
  //                 standard_reference_interval: '< 3.20 ',
  //                 standard_abnormal_indicator: '',
  //                 abnormal: true,
  //                 standard_normalized_quantitative: '',
  //                 abnormal_percent: 0.24,
  //               },
  //               {
  //                 laboratory_id: 2,
  //                 laboratory_name: '血糖检测',
  //                 standard_units: 'mmol/L',
  //                 standard_result: '3.95',
  //                 standard_result_type: 1,
  //                 standard_specimen: '血',
  //                 standard_reference_interval: '> 5.20 ',
  //                 standard_abnormal_indicator: '',
  //                 abnormal: true,
  //                 standard_normalized_quantitative: '',
  //                 abnormal_percent: -0.14,
  //               },
  //               {
  //                 laboratory_id: 3,
  //                 laboratory_name: '血清胃蛋白酶原',
  //                 standard_units: 'mmol/L',
  //                 standard_result: '2.95',
  //                 standard_result_type: 1,
  //                 standard_specimen: '血',
  //                 standard_reference_interval: '< 3.20 ',
  //                 standard_abnormal_indicator: '',
  //                 abnormal: false,
  //                 standard_normalized_quantitative: '',
  //                 abnormal_percent: -0.3,
  //               },
  //             ],
  //           },
  //           {
  //             name: ' 癌症胚胎抗原（CEA) ',
  //             abnormal: false,
  //             laboratories: [
  //             ],
  //           },
  //         ],
  //       }
  //     })
  //   }, 1000)
  // })
  return api.get(`/api/v1/mp/healthy/document/laboratories/detail`, {params: {id: payload}})
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/medical_cards`,payload)
}

