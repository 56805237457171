<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="lab_out">
        <div class="lab_left">
          <div
            class="left_item"
            :class="choose_index === index ? 'active' : ''"
            v-for="(item, index) in groups"
            :key="index"
            @click="switch_title(item, index)"
          >
            <div v-if="!item.abnormal">
              {{ item.name }}
            </div>
            <div v-else>
              <van-badge dot color="#FF3E27">
                {{ item.name }}
              </van-badge>
            </div>
          </div>
        </div>

        <div class="lab_right" ref="lab_right">
          <div v-if="choose_index === 0">
            <div v-for="(el, index) in right_data" :key="index">
              <div class="right_title">
                {{ el.name }}
              </div>
              <div
                class="right_box"
                v-for="(item, index) in el.laboratories"
                :key="index"
              >
                <div class="right_item">
                  <div class="right_item-name">
                    <div
                      class="right_item-circle"
                      :class="item.abnormal ? 'red' : ''"
                    ></div>
                    <div class="right_item-text">
                      {{ item.original_laboratory_name }}
                    </div>
                    <div
                      v-if="item.original_abnormal_indicator !== '未知'"
                      class="right_item-normal"
                      :class="item.abnormal ? 'not_normal' : ''"
                    >
                      {{ text_indicator(item.original_abnormal_indicator) }}
                    </div>
                  </div>
                  <div class="right_item-content">
                    结果：{{ item.original_result }}{{ item.standard_units }}
                    <span
                      v-if="item.abnormal && item.abnormal_percent"
                      class="right_item-proportion"
                    >
                      {{ item.abnormal_percent > 0 ? "↑" : "⬇"
                      }}{{ format_decimal(Math.abs(item.abnormal_percent)) }}%
                    </span>
                  </div>
                  <div class="right_item-content">
                    参考值：{{ item.original_reference }} 标本：{{
                      item.original_specimen
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="right_title">
              {{ recent_name }}
            </div>
            <div
              class="right_box"
              v-for="(item, index) in right_data"
              :key="index"
            >
              <div class="right_item">
                <div class="right_item-name">
                  <div
                    class="right_item-circle"
                    :class="item.abnormal ? 'red' : ''"
                  ></div>
                  <div class="right_item-text">
                    {{ item.original_laboratory_name }}
                  </div>
                  <div
                    class="right_item-normal"
                    :class="item.abnormal ? 'not_normal' : ''"
                  >
                    {{ text_indicator(item.original_abnormal_indicator) }}
                  </div>
                </div>
                <div class="right_item-content">
                  结果：{{ item.original_result }}{{ item.standard_units }}
                  <span
                    v-if="item.abnormal && item.abnormal_percent"
                    class="right_item-proportion"
                  >
                    {{ item.abnormal_percent > 0 ? "↑" : "⬇"
                    }}{{ format_decimal(Math.abs(item.abnormal_percent)) }}%
                  </span>
                </div>
                <div class="right_item-content">
                  参考值：{{ item.original_reference }} 标本：{{
                    item.original_specimen
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_lab } from "./servive";
import { format_decimal } from "../../utils/format";
export default {
  data() {
    return {
      data: null,
      groups: null,
      loading: true,
      id: null,
      choose_index: 0,
      right_data: null,
      recent_name: "",
      all: {
        name: "全部",
        abnormal: false,
        arr: [],
      },
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.id = this.$route.query.id;
    this.get_data(this.id);
  },
  methods: {
    format_decimal,
    text_indicator(param) {
      let text = "";
      switch (param) {
      case "N":
        text = "正常";
        break;
      case "H":
        text = "偏高";
        break;
      case "L":
        text = "偏低";
        break;
      default:
        text = param;
      }
      return text;
    },
    async get_data(id) {
      this.loading = true;
      let all = {
        abnormal: false,
        name: "全部",
        laboratories: [],
      };
      try {
        const { data } = await get_lab(id);
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          item.abnormal = false;
          item.laboratories.forEach((el) => {
            if (el.original_abnormal_indicator === "异常") {
              el.abnormal = true;
            }
            if (el.original_abnormal_indicator !== "N") {
              item.abnormal = true;
            }
          });
          if (item.name !== "异常") {
            all.laboratories.push(item);
          }
          if (item.abnormal) {
            all.abnormal = true;
          }
        }
        this.data = data;
        this.groups = data;
        this.groups.unshift(all);
        this.right_data = this.groups[this.choose_index].laboratories;
      } finally {
        this.loading = false;
      }
    },
    switch_title(item, index) {
      this.$refs.lab_right.scrollTop = 0;
      this.choose_index = index;
      this.right_data = item.laboratories;
      this.recent_name = this.groups[this.choose_index].name;
    },
  },
};
</script>

<style scoped>
.g_main_content {
  padding-bottom: 0;
}

.lab_out {
  display: flex;
}

.lab_left {
  width: 33.3%;
  background: #f2f7f8;
  height: 100vh;
  overflow-y: auto;
}

.lab_right {
  width: 66.7%;
  height: 100vh;
  overflow-y: auto;
}

.left_item {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 12px;
  font-size: 14px;
  color: #292929;
  position: relative;

  &.active {
    background: #fff;

    &::before {
      content: "";
      width: 3px;
      height: 36px;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -18px;
      background: #0088ff;
    }
  }
}

.right_box {
  padding: 0 10px 0 12px;
  color: #292929;
}

.right_title {
  padding: 0 16px;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.right_item {
  padding-bottom: 23px;
}

.right_item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.right_item-circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid #0088ff;
  margin-right: 5px;

  &.red {
    border: solid 2px #ff3e27;
  }
}

.right_item-text {
  flex: 1;
  max-height: 14px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.right_item-normal {
  font-size: 12px;
  color: #707070;

  &.not_normal {
    color: #ff1c00;
  }
}

.right_item-content {
  padding-top: 5px;
  font-size: 12px;
  line-height: 17px;
  color: #888888;
}

.right_item-proportion {
  color: #ff0000;
}
</style>
